import React from "react"
import { Element } from "react-scroll"

import TechLogos from "./TechLogos"
import Image from "./images/yohann_potico_v4.jpg"

import "./About.css"

function About() {
  return (
    <Element name="about_section" className="about_section">
      <div className="about_section">
        <div className="about_title">about</div>
        <TechLogos />
        <div className="about">
          <div className="profile_picture">
            <img src={Image} alt="" />
          </div>
          <div className="bio_section">
            <p className="bio">
              I’m a developer originally from France, now based in Brooklyn, NY.
              <br />
              I specialize in building full-stack applications and software that
              integrate payments and real-time features. Over the years, I've
              expanded my expertise into machine learning and financial
              engineering.
              <br />
              <br />
              Beyond development, I’m passionate about education and currently
              tutor coding boot camp students. I draw from my own experiences to
              offer guidance, encouragement, and insights to help new developers
              grow.
              <br />
              <br />
              With 20 years of experience as a musician, I approach programming
              with the mindset of a composer—focused on creating patterns,
              writing clean, modular code, and building software that’s
              intuitive, collaborative, and easy for other developers to work
              with, deconstruct, and reuse.
              <br />
              <br />
              Main tools:
              <li style={{ listStyleType: "square" }}>
                Languages/markups: HTML, CSS, JavaScript, R, Python,SQL, GraphQL
              </li>
              <li style={{ listStyleType: "square" }}>
                Frameworks/libraries: Django, React, Redux, NodeJS, Express,
                scikit-learn, NumPy, Pandas, PyTorch, Plotly, Matplotlib{" "}
              </li>
              <li style={{ listStyleType: "square" }}>
                Databases: PostgreSQL, MongoDB, Redis
              </li>
              <br />
              Learning:
              <br />
              <li style={{ listStyleType: "square" }}>
                Currently: Cybersecurity (CompTIA Security+), Rust
                <br />
              </li>
              <li style={{ listStyleType: "square" }}>
                On my list: Haskell, OCaml
              </li>
            </p>
          </div>
        </div>
      </div>
    </Element>
  )
}

export default About
