import React from "react"
import { Link } from "react-router-dom"
import { Element } from "react-scroll"

import QuizImg from "./images/quiz_img.png"
import SylentImg from "./images/sylent_img.png"
import shift5 from "./images/shift5.png"
import daniel_wallace from "./images/daniel_wallace_2.png"
import marianella from "./images/marianella.png"
import chartImg from "./images/p_and_l.gif"
import analysisChart from "./images/financial_analysis_looped.gif"

import "./Projects.css"

export default function Projects() {
  return (
    <Element name="project_section" className="project_section">
      <div className="projects_container">
        <div className="project_title">work</div>
        <div className="project">
          <div className="thumbnail quiz chart">
            <a
              href="https://github.com/yh2n/p_and_l_visualization"
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: "none" }}
              title="P&L"
            >
              <img src={chartImg} alt="" />
            </a>
          </div>
          <div className="description">
            <div className="description_text">
              <div className="description_text_title">
                <p>P&L visualization chart</p>
              </div>
              <p>
                {" "}
                Trade P&L application allowing users to zoom in on a time period
                as short as 5 min.
              </p>
            </div>
            <p className="used_tech">
              HTML, CSS, React, Python, Django, Sqlite, Highcharts
            </p>
            <div className="project_links">
              More info{" "}
              <a
                href="https://github.com/yh2n/p_and_l_visualization"
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  textDecoration: "none",
                  color: "#3B8696",
                }}
              >
                here{" "}
              </a>
            </div>
          </div>
        </div>
        <div className="project">
          <div className="thumbnail quiz chart">
            <a
              href="https://github.com/yh2n/financial-analysis-v2"
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: "none" }}
              title="Financial analysis"
            >
              <img src={analysisChart} alt="" />
            </a>
          </div>
          <div className="description">
            <div className="description_text">
              <div className="description_text_title">
                <p>Financial analysis</p>
              </div>
              <p>
                {" "}
                Application displaying two charts, each representing stocks
                seasonality –cumulative average daily return and one-month
                return.
              </p>
            </div>
            <p className="used_tech">
              HTML, CSS, Python, Django, Numpy, Pandas, Matplotlib, Yahoo API
            </p>
            <div className="project_links">
              More info{" "}
              <a
                href="https://github.com/yh2n/financial-analysis-v2"
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  textDecoration: "none",
                  color: "#3B8696",
                }}
              >
                here{" "}
              </a>
            </div>
          </div>
        </div>
        <div className="project">
          <div className="thumbnail quiz">
            <a
              href="https://wallacecorp.com/"
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: "none" }}
              title="Wallace Corp"
            >
              <img src={daniel_wallace} alt="" />
            </a>
          </div>
          <div className="description">
            <div className="description_text">
              <div className="description_text_title">
                <p>Daniel Wallace Workshop</p>
              </div>
              <p>
                {" "}
                Personal gallery of Daniel Wallace (Daniel Nadler alter ego)
                –technology entrepreneur, AI innovator, award-winnig poet, and
                visual artist.
              </p>
            </div>
            <p className="used_tech">
              HTML, CSS, React, Node.js, Express, Nodemailer
            </p>
            <div className="project_links">
              See it{" "}
              <a
                href="https://wallacecorp.com/"
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  textDecoration: "none",
                  color: "#3B8696",
                }}
              >
                live{" "}
              </a>
            </div>
          </div>
        </div>
        <div className="project">
          <div className="thumbnail quiz">
            <a
              href="https://marianella.co/"
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: "none" }}
              title="Mariannella"
            >
              <img src={marianella} alt="" />
            </a>
          </div>
          <div className="description">
            <div className="description_text">
              <div className="description_text_title">
                <p>Marianella</p>
              </div>
              <p>
                {" "}
                Online store for Marianella –a mother-son duo crafting
                cruelty-free and environment-friendly products.
                <br />{" "}
                <span className="marianella_credits">
                  {" "}
                  * Created and implemented a <i>reward</i> system for returning
                  customers.
                </span>
              </p>
            </div>
            <p className="used_tech">HTML, CSS, Shopify API, Yotpo</p>
            <div className="project_links">
              See it{" "}
              <a
                href="https://marianella.co/"
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  textDecoration: "none",
                  color: "#3B8696",
                }}
              >
                live{" "}
              </a>
            </div>
          </div>
        </div>
        <div className="project">
          <div className="thumbnail quiz">
            <a
              href="https://ez-shifts.herokuapp.com/"
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: "none" }}
              title="shift"
            >
              <img src={shift5} alt="" />
            </a>
          </div>
          <div className="description">
            <div className="description_text">
              <div className="description_text_title">
                <p>Shift</p>
              </div>
              <p>
                {" "}
                A schedule and staff management application designed and
                developed for restaurant workers. <br />
                <span style={{ fontStyle: "italic" }}>shift </span>
                enables all FOH employees to receive schedule updates (new
                schedule available, shift coverage requests etc) in real time.
                <br />
                This is a work in progress. Next steps: implementing chat and
                direct messages to managers, allowing documents upload (menu
                updates, food/wine description), enabling management of multiple
                venues from one account.
              </p>
            </div>
            <p className="used_tech">
              HTML, CSS, React, Redux, Node.js, Express, MongoDB, Pusher.js,
              Faker.js
            </p>
            <div className="project_links">
              See it{" "}
              <a
                href="https://ez-shifts.herokuapp.com/"
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  textDecoration: "none",
                  color: "#3B8696",
                }}
              >
                live{" "}
              </a>
              {/* | Explore the{" "}
              <Link
                to="//github.com/yh2n/shift-client"
                target="_blank"
                style={{
                  textDecoration: "none",
                  color: "#3B8696",
                }}
              >
                code
              </Link> */}
            </div>
          </div>
        </div>
        <div className="project three">
          <div className="thumbnail sylent">
            <Link
              to="//yh2n.github.io/sylent/"
              target="_blank"
              style={{ textDecoration: "none" }}
              title="Sylent"
            >
              <img src={SylentImg} alt="" />
            </Link>
          </div>
          <div className="description">
            <div className="description_text">
              <div className="description_text_title">
                <p>Sylent</p>
              </div>
              <p>
                {" "}
                A music credits application using the Discogs API.
                <br />
                Sylent is a tribute to the unacclaimed talents who shaped great
                recordings.
              </p>
            </div>
            <p className="used_tech">HTML, CSS, jQuery, Discogs API</p>
            <div className="project_links">
              See it{" "}
              <Link
                to="//yh2n.github.io/sylent/"
                target="_blank"
                style={{
                  textDecoration: "none",
                  color: "#3B8696",
                }}
              >
                live{" "}
              </Link>{" "}
              {/* | Explore the{" "}
              <Link
                to="//github.com/yh2n/sylent"
                target="_blank"
                style={{
                  textDecoration: "none",
                  color: "#3B8696",
                }}
              >
                code
              </Link> */}
            </div>
          </div>
        </div>
        {/* <div className="project four">
          <div className="thumbnail quiz">
            <Link
              to="//yh2n.github.io/quiz_app/"
              target="_blank"
              style={{ textDecoration: "none" }}
              title="Music quiz"
            >
              <img src={QuizImg} alt="" />
            </Link>
          </div>
          <div className="description">
            <div className="description_text">
              <p>Music Quiz</p>
              <p>
                {" "}
                The first app I created while learning JavaScript. Also my very
                first challenge with state management! <br />
              </p>
            </div>
            <p className="used_tech">HTML, CSS, jQuery</p>
            <div className="project_links">
              See it{" "}
              <Link
                to="//yh2n.github.io/quiz_app/"
                target="_blank"
                style={{
                  textDecoration: "none",
                  color: "#3B8696",
                }}
              >
                live{" "}
              </Link>
              | Explore the{" "}
              <Link
                to="//github.com/yh2n/quiz_app/tree/gh-pages"
                target="_blank"
                style={{
                  textDecoration: "none",
                  color: "#3B8696",
                }}
              >
                code
              </Link>
            </div>
          </div>
        </div> */}
      </div>
    </Element>
  )
}
